import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'
import '../theme/bootstrap-grid.css'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Heading,
  HeroSection,
  Image,
  Layout,
  LeadSection,
  Markdown,
  Text
} from '../components'

export interface CareersTemplateProps {
  seoTitle: string
  metaDescription: string
  hero: {
    heading: string
    subheading: string
    text: string
    background: string
  }
  lead: {
    heading: string
    text: string
  }
  details: Array<{
    heading: string
    text: string
    icon: string
    benefits: Array<{
      text: string
    }>
  }>
  openings: Array<{
    role: string
    markdown: string
  }>
  application: {
    heading: string
    text: string
    pdf: string
  }
}

const CareersPageTemplate: React.FC<CareersTemplateProps> = ({
  seoTitle,
  metaDescription,
  hero,
  lead,
  details,
  openings,
  application
}) => {
  return (
    <>
      <Helmet>
        <title>Incobrasa | {seoTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroSection {...hero} />
      <Box my="50">
        <Container>
          <LeadSection {...lead} />
        </Container>
      </Box>
      <Box mb="100" position="relative">
        <Container wide>
          <Box className="row">
            {details.map(({ heading, text, icon, benefits }) => (
              <Box className="col col-12 col-md-4" mb="medium" key={heading}>
                <Card>
                  <Image
                    width="50"
                    height="50"
                    mr="medium"
                    flexShrink="0"
                    mb="large"
                    src={icon}
                    alt={icon}
                  />
                  <Heading level="4" mb="medium" textTransform="uppercase">
                    {heading}
                  </Heading>
                  {text && (
                    <Text size="small" whiteSpace="break-spaces">
                      {text}
                    </Text>
                  )}
                  {benefits?.map((benefit) => (
                    <Flex key={benefit.text} mb="xsmall" alignItems="center">
                      <Image src="/img/check.svg" alt="check" mr="small" />
                      <Text size="small">{benefit.text}</Text>
                    </Flex>
                  ))}
                </Card>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      <Box>
        <Container>
          <Heading level="2" mb="xlarge" textTransform="uppercase">
            Job Openings
          </Heading>
          <Accordion collapsible multiple>
            {openings.map((opening) => (
              <AccordionItem
                mb="xlarge"
                borderRadius="8"
                boxShadow="0px 15px 30px rgba(0, 0, 0, 0.1)"
                key={opening.role}
                css={css`
                  &[data-reach-accordion-item][data-state='open']
                    [data-reach-accordion-button]
                    img {
                    transform: rotate(135deg);
                  }
                `}
              >
                <AccordionButton
                  p="large"
                  border="0"
                  display="flex"
                  width="100%"
                  bg="transparent"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Heading level="3">{opening.role}</Heading>
                  <Image
                    src="/img/plus-circle.svg"
                    alt="expand"
                    width="37"
                    height="37"
                    transition="transform 200ms"
                  />
                </AccordionButton>
                <AccordionPanel py="large" px="xxlarge">
                  <Markdown
                    css={css`
                      p,
                      ul {
                        font-size: 14px;
                      }
                    `}
                  >
                    {opening.markdown}
                  </Markdown>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Box>
      <Box mb="120">
        <Container>
          <Box bg="lime" borderRadius="8" textAlign="center" p="xlarge">
            <Heading
              level="3"
              color="primary"
              mb="large"
              textTransform="uppercase"
            >
              {application.heading}
            </Heading>
            <Text
              size="small"
              whiteSpace="break-spaces"
              maxWidth="600"
              mx="auto"
              mb="large"
            >
              {application.text}
            </Text>
            <Button
              href={application.pdf}
              // @ts-ignore
              target="_blank"
              py="medium"
              size="large"
              width="300"
              maxWidth="100%"
              textTransform="uppercase"
            >
              Apply Now
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

interface CareersPageProps {
  data: {
    markdownRemark: {
      frontmatter: CareersTemplateProps
    }
  }
}

const CareersPage: React.FC<CareersPageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout transparentNavbarOnTop>
      <CareersPageTemplate {...frontmatter} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers" } }) {
      frontmatter {
        seoTitle
        metaDescription
        hero {
          heading
          subheading
          text
          background
        }
        lead {
          heading
          text
        }
        details {
          heading
          text
          icon
          benefits {
            text
          }
        }
        openings {
          role
          markdown
        }
        application {
          heading
          text
          pdf
        }
      }
    }
  }
`

export { CareersPageTemplate }
export default CareersPage
